import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MinusOutlined, PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Alert, Button, Badge, Col, Divider, Form, List, message, Row, Skeleton, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdOpenInNew } from 'react-icons/md';

import { useGetProductDetails } from 'apis/product';

import { withContextCart } from 'contexts/ContextCart/ContextCart';
import FormInput from 'components/Input/FormInput/FormInput';
import OutOfStockLabel from 'components/_local/OutOfStockLabel/OutOfStockLabel';

import ImagePlaceholder from 'images/no-image-placeholder.jpg';

import { useLocationQuery } from 'hooks/router';
import { getHomeRoute, getCartRoute, useNav } from 'utils/routes';
import { generateQtyLimit } from 'utils/cart';
import { guard } from 'utils/general';

import {
  CartIcon,
  ContainerModal,
  PhotoCol,
  ProductName,
  PricingInfoLabel,
  ProductKeyword,
  ProductDesc,
  ProductNameWithVariances,
  ProductKeywordWithVariances,
  QtyButtonGroupContainer,
  OriPricingInfoLabel,
  QtyButton,
  StyledImagesPreview
} from './ProductDetailsModal.styles';

const homeRoute = getHomeRoute();
const cartRoute = getCartRoute();

const { useForm } = Form;
const {
  Item: ListItem,
  Item: { Meta: ListItemMeta }
} = List;

const ProductInfo = ({ t, name, keyword, keywordComment, desc, tags, isVariance = false, isLiveSellingProduct = false }) => {
  return (
    <>
      {!isVariance ? <ProductName>{name}</ProductName> : <ProductNameWithVariances>{name}</ProductNameWithVariances>}
      {!isVariance ? <ProductKeyword>{keyword}</ProductKeyword> : <ProductKeywordWithVariances>{keyword}</ProductKeywordWithVariances>}
      {desc && <ProductDesc>{desc}</ProductDesc>}
      {tags &&
        tags.map(tag => (
          <Tag key={tag.storeMsCategoryId} style={{ marginBottom: '12px' }}>
            #{tag.label}
          </Tag>
        ))}
      {isLiveSellingProduct && keywordComment && (
        <Alert
          showIcon
          message={
            <span>
              <span>{t('modalProductDetails:comment-fb-alert-message')}</span> <b>{keywordComment} + 1</b>
            </span>
          }
        />
      )}
    </>
  );
};

const PricingAndAddToCart = ({
  form,
  formInputName,
  priceAmt,
  oriPrice,
  limitQty = 0,
  spaceTop = '24px',
  hasStock,
  addedQty = 0,
  isLiveSellingProduct = false,
  onChangeQtyByBtn
}) => {
  const { t } = useTranslation(['modalProductDetails']);
  const handleOnClickAddQty = () => {
    const formValue = form.getFieldsValue([formInputName]);
    const currentValue = formValue[formInputName];
    const newValue = !currentValue ? 1 : Number(currentValue) + 1;

    form.setFieldsValue({ [formInputName]: newValue });
    form.validateFields([formInputName]);

    onChangeQtyByBtn(formInputName, newValue);
  };

  const handleOnClickDeductQty = () => {
    const formValue = form.getFieldsValue([formInputName]);
    const currentValue = formValue[formInputName];
    const newValue = currentValue > 1 ? Number(currentValue) - 1 : undefined;

    if (!currentValue) {
      return;
    } else {
      form.setFieldsValue({ [formInputName]: newValue });
      form.validateFields([formInputName]);

      onChangeQtyByBtn(formInputName, newValue);
    }
  };

  return (
    <Row justify="space-between" style={{ marginTop: spaceTop }}>
      <Col>
        {oriPrice && <OriPricingInfoLabel amount={oriPrice} />}
        <PricingInfoLabel amount={priceAmt} hasOriPrice={!!oriPrice} />
      </Col>
      <Col span={24} sm={12} md={10} xl={12}>
        {hasStock ? (
          !isLiveSellingProduct && (
            <QtyButtonGroupContainer>
              <QtyButton icon={<MinusOutlined />} onClick={handleOnClickDeductQty} />
              <FormInput
                name={formInputName}
                placeholder="e.g.: 2"
                isNumeric
                onChange={() => {
                  form.validateFields([formInputName]);
                }}
                extraRules={[
                  {
                    validator: (_, value) => {
                      if (Number(value) + Number(addedQty) > limitQty) {
                        return Promise.reject();
                      } else {
                        return Promise.resolve();
                      }
                    },
                    message: t('modalProductDetails:product-error-message-not-enough-stock', { limitQty, addedQty })
                  },
                  {
                    validator: (_, value) => {
                      if (!!value && Number(value) < 1) {
                        return Promise.reject();
                      } else {
                        return Promise.resolve();
                      }
                    },
                    message: t('modalProductDetails:product-error-message-invalid-quantity')
                  }
                ]}
              />
              <QtyButton icon={<PlusOutlined />} onClick={handleOnClickAddQty} />
            </QtyButtonGroupContainer>
          )
        ) : (
          <OutOfStockLabel />
        )}
      </Col>
    </Row>
  );
};

const MainProductView = ({ form, selectedProduct, existingProductItem, isLiveSellingProduct, onChangeQtyByBtn }) => {
  const { t } = useTranslation(['modalProductDetails']);

  const hasStock = selectedProduct.inventory > 0;

  const productPhotoUrl = selectedProduct.coverPhoto?.original?.url || selectedProduct.coverPhotoFile?.url;
  const productPhotoUrls =
    selectedProduct.photos?.map(photo => {
      return photo.original.url;
    }) ||
    selectedProduct.photoFiles?.map(photoFile => {
      return photoFile.url;
    });

  return (
    <Row gutter={32} style={{ margin: 0 }}>
      <PhotoCol span={24} md={8}>
        <StyledImagesPreview
          thumbnailImageUrl={productPhotoUrl || ImagePlaceholder}
          previewImageUrl={productPhotoUrl || ImagePlaceholder}
          imageUrls={productPhotoUrls}
        />
      </PhotoCol>
      <Col span={24} md={16}>
        <ProductInfo
          t={t}
          name={selectedProduct.label}
          keyword={selectedProduct.keyword}
          keywordComment={selectedProduct.keyword}
          desc={selectedProduct.description}
          tags={selectedProduct.msCategories}
          isLiveSellingProduct={isLiveSellingProduct}
        />
        <PricingAndAddToCart
          form={form}
          formInputName={selectedProduct._id}
          priceAmt={selectedProduct.msPrice}
          oriPrice={selectedProduct.oriPrice}
          limitQty={generateQtyLimit(selectedProduct.inventory, selectedProduct.purchaseLimit)}
          hasStock={hasStock}
          addedQty={existingProductItem && existingProductItem.quantity}
          isLiveSellingProduct={isLiveSellingProduct}
          onChangeQtyByBtn={onChangeQtyByBtn}
        />
      </Col>
    </Row>
  );
};

const ProductWithVariancesView = ({ form, selectedProduct, existingProductItems, isLiveSellingProduct, onChangeQtyByBtn }) => {
  const { t } = useTranslation(['modalProductDetails']);

  return (
    <Row gutter={32} style={{ margin: 0 }}>
      <Col span={24}>
        <ProductInfo
          t={t}
          name={selectedProduct.label}
          keyword={selectedProduct.keyword}
          desc={
            selectedProduct.description ||
            t('modalProductDetails:product-fallback-variance-desc', { variancesLabel: selectedProduct.variances.map(v => v.label).join(', ') })
          }
          tags={selectedProduct.msCategories}
          isLiveSellingProduct={isLiveSellingProduct}
        />
      </Col>
      <Col span={24}>
        <Divider />
        <List
          itemLayout="horizontal"
          dataSource={selectedProduct.variances}
          renderItem={variance => {
            const existingProductItem = existingProductItems.find(existingProductItem => variance._id === existingProductItem.varianceId);

            const variancePhotoUrl =
              variance.coverPhoto || variance.coverPhotoFile
                ? variance.coverPhoto?.original?.url || variance.coverPhotoFile?.url
                : selectedProduct.coverPhoto?.original?.url || selectedProduct.coverPhotoFile?.url;

            const variancePhotoUrls =
              variance.photos?.map(photo => {
                return photo.original.url;
              }) ||
              variance.photoFiles?.map(photoFile => {
                return photoFile.url;
              });

            return (
              <ListItem key={variance._id}>
                <ListItemMeta
                  description={
                    <div style={{ width: '100%' }}>
                      <Row gutter={32} style={{ margin: 0 }}>
                        <PhotoCol span={24} md={6}>
                          <StyledImagesPreview
                            thumbnailImageUrl={variancePhotoUrl || ImagePlaceholder}
                            previewImageUrl={variancePhotoUrl || ImagePlaceholder}
                            imageUrls={variancePhotoUrls}
                          />
                        </PhotoCol>
                        <Col span={24} md={18}>
                          <ProductInfo
                            t={t}
                            name={variance.label}
                            keyword={variance.keyword}
                            keywordComment={`${selectedProduct.keyword}${variance.keyword}`}
                            desc={variance.description || selectedProduct.description}
                            isVariance
                            isLiveSellingProduct={isLiveSellingProduct}
                          />
                          <PricingAndAddToCart
                            form={form}
                            formInputName={`${selectedProduct._id}_${variance._id}`}
                            priceAmt={variance.msPrice}
                            oriPrice={variance.oriPrice}
                            limitQty={generateQtyLimit(variance.inventory, variance.purchaseLimit)}
                            hasStock={variance.inventory > 0}
                            addedQty={existingProductItem && existingProductItem.quantity}
                            isLiveSellingProduct={isLiveSellingProduct}
                            onChangeQtyByBtn={onChangeQtyByBtn}
                          />
                        </Col>
                      </Row>
                    </div>
                  }
                />
              </ListItem>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const ProductWithVariancesCompactView = ({ form, selectedProduct, existingProductItems, isLiveSellingProduct, onChangeQtyByBtn }) => {
  const { t } = useTranslation(['modalProductDetails']);
  const [selectedVariance, setSelectedVariance] = useState(null);

  const existingProductItem = existingProductItems.find(
    existingProductItem => selectedVariance && selectedVariance._id === existingProductItem.varianceId
  );

  const productPhotoUrl =
    selectedVariance && (selectedVariance.coverPhoto || selectedVariance.coverPhotoFile)
      ? selectedVariance.coverPhoto?.original?.url || selectedVariance.coverPhotoFile?.url
      : selectedProduct.coverPhoto?.original?.url || selectedProduct.coverPhotoFile?.url;

  const productPhotoUrls =
    (selectedVariance ? selectedVariance : selectedProduct).photos?.map(photo => {
      return photo.original.url;
    }) ||
    (selectedVariance ? selectedVariance : selectedProduct).photoFiles?.map(photoFile => {
      return photoFile.url;
    });

  return (
    <>
      <Row gutter={32} style={{ margin: 0 }}>
        <Col span={24}>
          <ProductName>{selectedProduct.label}</ProductName>
        </Col>
        <Col span={24} md={selectedVariance ? 6 : 8}>
          <PhotoCol span={24} style={{ height: '100%' }}>
            <StyledImagesPreview
              thumbnailImageUrl={productPhotoUrl || ImagePlaceholder}
              previewImageUrl={productPhotoUrl || ImagePlaceholder}
              imageUrls={productPhotoUrls}
            />
          </PhotoCol>
        </Col>
        <Col span={24} md={selectedVariance ? 18 : 16}>
          <ProductInfo
            t={t}
            name={selectedVariance ? selectedVariance.label : ''}
            keyword={selectedVariance ? selectedVariance.keyword : selectedProduct.keyword}
            keywordComment={selectedVariance ? `${selectedProduct.keyword}${selectedVariance.keyword}` : ''}
            desc={
              selectedVariance
                ? selectedVariance.description || selectedProduct.description
                : selectedProduct.description ||
                  t('modalProductDetails:product-fallback-variance-desc', { variancesLabel: selectedProduct.variances.map(v => v.label).join(', ') })
            }
            tags={selectedVariance ? [] : selectedProduct.msCategories}
            isVariance={!!selectedVariance}
            isLiveSellingProduct={isLiveSellingProduct}
          />
          {selectedVariance && (
            <PricingAndAddToCart
              form={form}
              formInputName={`${selectedProduct._id}_${selectedVariance._id}`}
              priceAmt={selectedVariance.msPrice}
              oriPrice={selectedVariance.oriPrice}
              limitQty={generateQtyLimit(selectedVariance.inventory, selectedVariance.purchaseLimit)}
              hasStock={selectedVariance.inventory > 0}
              addedQty={existingProductItem && existingProductItem.quantity}
              isLiveSellingProduct={isLiveSellingProduct}
              onChangeQtyByBtn={onChangeQtyByBtn}
            />
          )}
        </Col>
        <Col span={24}>
          <Divider style={{ margin: '12px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedProduct.variances.map(variance => {
              return (
                <Button
                  type="primary"
                  style={{ margin: '0 8px 16px' }}
                  ghost={selectedVariance?._id !== variance._id}
                  disabled={!(variance.inventory > 0)}
                  onClick={() => {
                    if (selectedVariance?._id === variance._id) {
                      setSelectedVariance(null);
                      return;
                    }
                    setSelectedVariance(variance);
                  }}
                >
                  {variance.label}
                </Button>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

const ProductDetailsModal = ({ propsContextCart }) => {
  const { productItems, addProductItems, isCompactLayoutView } = propsContextCart;
  const { t } = useTranslation(['modalProductDetails']);
  const [form] = useForm();
  const nav = useNav();
  const { productId } = useParams();
  const query = useLocationQuery();
  const postId = query.post;

  const [isVisible, setIsVisible] = useState(true);
  const [hasInitValue, setHasInitValue] = useState(false);
  const [isAllowAddToCart, setIsAllowAddToCart] = useState(false);
  const { isLoading: isProductLoading, data: product } = useGetProductDetails(productId, { ...(postId && { postId }) });

  const isLiveSellingProduct = guard(() => !!product.liveVideoUrl);
  const existingProductItems = useMemo(() => productItems.filter(productItem => productItem.productId === productId), [productItems, productId]);

  const generateProductsToAdd = formValues => {
    const filteredFormValues = Object.keys(formValues).reduce((values, key) => {
      if (!!formValues[key]) {
        values.push({ [key]: Number(formValues[key]) });
        return values;
      }
      return values;
    }, []);
    const productsToAdd = [];
    filteredFormValues.forEach(filteredFormValue => {
      const key = Object.keys(filteredFormValue)[0];
      const ids = key.split('_');
      const hasVariance = ids.length > 1;
      if (hasVariance) {
        const detailsOfProductToAdd = product.variances.find(variance => variance._id === ids[1]);
        const productToAdd = {
          _id: `${productId}_${detailsOfProductToAdd._id}`,
          productId,
          varianceId: detailsOfProductToAdd._id,
          label: `${product.label} - ${detailsOfProductToAdd.label}`,
          priceAmount: detailsOfProductToAdd.msPrice,
          oriPrice: detailsOfProductToAdd.oriPrice,
          coverPhotoUrl:
            (detailsOfProductToAdd.coverPhotoFile && detailsOfProductToAdd.coverPhotoFile.url) ||
            (product.coverPhotoFile && product.coverPhotoFile.url),
          coverPhoto: detailsOfProductToAdd.coverPhoto || product.coverPhoto,
          description: detailsOfProductToAdd.description || product.description,
          inventory: detailsOfProductToAdd.inventory,
          purchaseLimit: detailsOfProductToAdd.purchaseLimit,
          quantity: filteredFormValue[key]
        };
        productsToAdd.push(productToAdd);
      } else {
        const detailsOfProductToAdd = product;
        const productToAdd = {
          _id: `${productId}_${detailsOfProductToAdd._id}`,
          productId,
          label: detailsOfProductToAdd.label,
          priceAmount: detailsOfProductToAdd.msPrice,
          coverPhotoUrl: detailsOfProductToAdd.coverPhotoFile && detailsOfProductToAdd.coverPhotoFile.url,
          coverPhoto: detailsOfProductToAdd.coverPhoto,
          description: detailsOfProductToAdd.description,
          inventory: detailsOfProductToAdd.inventory,
          purchaseLimit: detailsOfProductToAdd.purchaseLimit,
          quantity: filteredFormValue[key]
        };
        productsToAdd.push(productToAdd);
      }
    });
    return productsToAdd;
  };

  const handleOnClickAddToCart = async e => {
    e.preventDefault();
    try {
      const formValues = await form.validateFields();
      const productsToAdd = generateProductsToAdd(formValues);
      addProductItems(productsToAdd)
        .then(() => {
          form.resetFields();
          setIsAllowAddToCart(false);
          message.success(t('modalProductDetails:add-to-cart-success-message'));
        })
        .catch(ex => {
          message.error(ex.message);
        });
    } catch (error) {
      error && error.errorFields && error.errorFields.forEach(field => message.error(field.errors[0]));
    }
  };

  const handleOnChangeQtyByBtn = (fieldName, newValue) => {
    const allFieldsValue = {
      ...form.getFieldsValue(),
      [fieldName]: newValue
    };
    const foundFormWithValue = Object.values(allFieldsValue).find(formProductQty => !!formProductQty && Number(formProductQty) > 0);
    setIsAllowAddToCart(!!foundFormWithValue);
  };

  useEffect(() => {
    if (!hasInitValue && product && product._id && (!product.variances || product.variances.length === 0)) {
      setHasInitValue(true);
      form.setFieldsValue({ [product._id]: 1 });
      setIsAllowAddToCart(true);
    }
  }, [product, form, hasInitValue]);

  return (
    <>
      {product && product.label && (
        <Helmet>
          <title>{product.label}</title>
        </Helmet>
      )}
      <Form
        form={form}
        style={{ width: '100%' }}
        onValuesChange={(_, allValues) => {
          const foundFormWithValue = Object.values(allValues).find(formProductQty => !!formProductQty && Number(formProductQty) > 0);
          setIsAllowAddToCart(!!foundFormWithValue);
        }}
      >
        {isVisible && (
          <ContainerModal
            visible={isVisible}
            footer={
              <Row gutter={8}>
                <Col flex="auto">
                  {isLiveSellingProduct ? (
                    <Button
                      type="primary"
                      ghost
                      block
                      onClick={() => {
                        window.open(product.liveVideoUrl, '_blank');
                      }}
                      disabled={isProductLoading || !(product.inventory > 0)}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {t('modalProductDetails:buy-from-fb-button')} <MdOpenInNew size="16px" style={{ marginLeft: '4px' }} />
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      ghost
                      block
                      icon={<ShoppingCartOutlined />}
                      onClick={handleOnClickAddToCart}
                      disabled={isProductLoading || !(product.inventory > 0) || !isAllowAddToCart}
                    >
                      {t('modalProductDetails:add-to-cart-button')}
                    </Button>
                  )}
                </Col>
                {existingProductItems && existingProductItems.length > 0 && (
                  <Col>
                    <Button
                      type="text"
                      icon={
                        <Badge dot>
                          <CartIcon />
                        </Badge>
                      }
                      onClick={() => nav(cartRoute.path)}
                    />
                  </Col>
                )}
              </Row>
            }
            onCancel={() => {
              setIsVisible(false);
              nav(homeRoute.path);
            }}
            destroyOnClose
          >
            {isProductLoading ? (
              <Skeleton active />
            ) : product.variances && product.variances.length > 0 ? (
              !isCompactLayoutView ? (
                <ProductWithVariancesView
                  form={form}
                  selectedProduct={product}
                  existingProductItems={existingProductItems}
                  isLiveSellingProduct={isLiveSellingProduct}
                  onChangeQtyByBtn={handleOnChangeQtyByBtn}
                />
              ) : (
                <ProductWithVariancesCompactView
                  form={form}
                  selectedProduct={product}
                  existingProductItems={existingProductItems}
                  isLiveSellingProduct={isLiveSellingProduct}
                  onChangeQtyByBtn={handleOnChangeQtyByBtn}
                />
              )
            ) : (
              <MainProductView
                form={form}
                selectedProduct={product}
                existingProductItem={existingProductItems[0]}
                isLiveSellingProduct={isLiveSellingProduct}
                onChangeQtyByBtn={handleOnChangeQtyByBtn}
              />
            )}
          </ContainerModal>
        )}
      </Form>
    </>
  );
};

export default withContextCart(ProductDetailsModal);
