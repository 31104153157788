import styled from '@emotion/styled';
import { Col, Image, Row, Typography } from 'antd';

const mobileContentPadding = 32; // if change, please change for layoutMain too.
const tabletContentPadding = 60; // if change, please change for layoutMain too.

export const BannerContainer = styled.div`
  width: 100vw;
  max-width: 768px;
  padding-top: 56.25%;
  background-image: ${props => `url("${props.imageUrl}")`}; // 320 x 100 size
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eff2f5;
  margin-left: -${mobileContentPadding}px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-left: -${tabletContentPadding}px;
  }
`;

export const SectionTitle = styled.h2`
  color: ${props => props.theme.color.tertiary};
  font-size: 24px;
  padding-left: ${mobileContentPadding}px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    padding-left: ${tabletContentPadding}px;
  }
`;

export const FeatureSectionContainer = styled.div`
  margin-left: -${mobileContentPadding}px;
  margin-bottom: 32px;
  width: 100vw;
  max-width: 768px;

  @media (min-width: 768px) {
    margin-left: -${tabletContentPadding}px;
  }
`;

export const FeatureProductsScrollableContainer = styled.div`
  width: 100%;
  padding: 8px 0;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FeaturedProductRow = styled(Row)`
  display: inline-flex;
  width: max-content;
  padding: 0 ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 0 ${tabletContentPadding}px;
  }
`;

export const FeaturedProductCol = styled(Col)`
  /* (fullWidth - mainContentPadding + colGap) / colNum */
  width: calc((100vw - (${mobileContentPadding}px * 2) + (8px * 2)) / 2);

  @media (min-width: 768px) {
    width: calc((768px - (${tabletContentPadding}px * 2) + (6px * 2)) / 3);
  }
`;

export const DescSection = styled.div`
  width: 100vw;
  max-width: 768px;
  background-color: ${props => props.theme.color.lightGrey};
  padding: 24px ${mobileContentPadding}px;
  margin-left: -${mobileContentPadding}px;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    padding: 32px ${tabletContentPadding}px;
    margin-left: -${tabletContentPadding}px;
  }
`;

export const DescContentContainer = styled.div`
  text-align: center;
`;

export const Desc = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  white-space: pre-wrap;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const ProductCardContainer = styled.div`
  padding: 12px 0;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

export const SearchResultText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export const SearchKeywordText = styled.span`
  color: ${props => props.theme.color.secondary};
`;

export const LoadMoreButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const ProductCardRow = styled(Row)`
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: left;
  }
`;

export const StoreInfoContainer = styled.div`
  position: relative;
`;

export const StoreAvatarContainer = styled.div`
  position: absolute;
  top: -30vw;

  @media (min-width: 576px) {
    top: -25vw;
  }

  @media (min-width: 768px) {
    top: -192px;
  }
`;

export const StoreAvatarCircle = styled.div`
  width: 30vw;
  height: 30vw;
  background: ${props => (props.isLive ? 'linear-gradient(to right, #FF7212, #F5212D)' : 'white')};
  padding: 4px;
  border-radius: 50%;
  box-shadow: 0px 3px 8px ${props => props.theme.color.tertiary}33;
  cursor: ${props => (props.isLive ? 'pointer' : 'default')};

  @media (min-width: 576px) {
    width: 25vw;
    height: 25vw;
  }

  @media (min-width: 768px) {
    width: 192px;
    height: 192px;
    padding: 8px;
  }
`;

export const StoreAvatarImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const StoreAvatarText = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.color.tertiary};
  background-color: ${props => props.theme.color.primary};
  font-size: 8vw;
  font-family: ${props => props.theme.fontFamily.title};
  padding: 12px;
  margin-bottom: 0;
  border-radius: 50%;

  @media (min-width: 768px) {
    font-size: 52px;
  }
`;

export const LiveTag = styled.span`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.color.white};
  padding: 0 12px;
  border-radius: 4px;
  background: linear-gradient(to bottom, #f5212d, #ff7212);

  @media (min-width: 768px) {
    bottom: -4px;
    font-size: 20px;
  }
`;

export const StoreNameText = styled.h1`
  color: ${props => props.theme.color.tertiary};
  font-size: 16px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const LiveContainer = styled(Row)`
  background: linear-gradient(to right, #ff7212, #f5212d);
  background-clip: padding-box;
  border-radius: 8px;
  margin-bottom: 32px;
  width: 100%;
  padding: 4px;
`;

export const LiveInframeContainer = styled.div`
  background-color: black;

  @media (min-width: 768px) {
    border-bottom-left-radius: 8px;
  }
`;

export const LiveInframeOverlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  line-height: 1.4;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  :hover {
    background-color: black;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.color.white};
    font-size: 24px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const LiveIframe = styled.iframe`
  display: block;
  border: none;
  overflow: hidden;
  margin: 0 auto;
  height: 300px;
  max-width: 168px;
  background: black;
`;

export const LiveSellingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.lightGrey};
  height: 300px;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (min-width: 768px) {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
`;

export const LiveSellingTitle = styled.h4`
  color: ${props => props.theme.color.tertiary};
  font-size: 14px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const LiveProductContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WatchLiveLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: ${props => props.theme.color.tertiary};
`;

export const LiveInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.color.white};

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px 8px;
`;

export const LiveNowContainer = styled.div`
  margin-right: 8px;
  min-width: fit-content;
  animation: ${props => (props.isLive ? '1s blink ease infinite' : undefined)};

  @keyframes "blink" {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

export const LiveText = styled.h4`
  display: none;
  color: ${props => props.theme.color.tertiary};
  font-size: 16px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

export const LiveDescription = styled.h4`
  color: ${props => props.theme.color.tertiary};
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0;
`;

export const BlinkingRedLightSVG = styled.svg`
  margin-right: 0;

  @media (min-width: 768px) {
    margin-right: 8px;
  }
`;

export const PastLiveSectionContainer = styled.div`
  margin-left: -${mobileContentPadding}px;
  margin-bottom: 32px;
  width: 100vw;
  max-width: 768px;

  @media (min-width: 768px) {
    margin-left: -${tabletContentPadding}px;
  }
`;

export const PastLiveScrollableContainer = styled.div`
  width: 100%;
  padding: 8px 0;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PastLiveRow = styled(Row)`
  display: inline-flex;
  width: max-content;
  padding: 0 ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 0 ${tabletContentPadding}px;
  }
`;

export const PastLiveItem = styled(Row)`
  width: calc((100vw - (${mobileContentPadding}px * 2) + (8px * 2)) / 1.3);
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: 100%;

  @media (min-width: 768px) {
    width: calc((768px - (${tabletContentPadding}px * 2) + (6px * 2)) / 2);
  }
`;

export const PastLiveInframeContainer = styled(LiveInframeContainer)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
`;

export const PastLiveIframe = styled.iframe`
  display: block;
  border: none;
  overflow: hidden;
  margin: 0 auto;
  height: 240px;
  max-width: 168px;
  background: black;
`;

export const PastLiveSellingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.lightGrey};
  height: 300px;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (min-width: 768px) {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
`;

export const PastLiveInframeDescOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1.4;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(0deg, black, transparent);
  display: flex;
  align-items: end;
  padding: 8px 16px;
`;

export const PastLiveInframeDesc = styled(Typography.Paragraph)`
  color: ${props => props.theme.color.white};
`;
