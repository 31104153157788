import { useState, useCallback, useMemo, useEffect, createContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';

import { postCartValidate, postGetPendingOrder, postCheckoutCart, useGetCustomerPendingOrders } from 'apis/cart';
import { useIsLoadings } from 'hooks/general';
import { useLocalStorage } from 'hooks/utils';
import { useLocationQuery } from 'hooks/router';
import { checkHasValue, cloneNewObject, guard } from 'utils/general';
import { logError } from 'utils/logging';
import { addMoment } from 'utils/date';
import { DATE_FORMAT_DATE_OBJ } from 'utils/constants';

const ContextCart = createContext();

const LS_KEY_CART_PRODUCT_ITEMS = 'cart-product-items';
const LS_KEY_CART_SOURCE_ORDER = 'combine-order';
const COOKIE_KEY_SESSION = 'cart-session';
const COOKIE_KEY_CUSTOMER = 'customer';

const PRODUCT_ITEM_ERROR_CODE = '1000';
const PRODUCT_ITEM_ERRORS_MAPPING = {
  10001: {
    code: '10001',
    messageTranslationTag: 'contextCart:cart-error-invalid-product'
  },
  10002: {
    code: '10002',
    messageTranslationTag: 'contextCart:cart-error-invalid-product-variance'
  },
  10003: {
    code: '10003',
    messageTranslationTag: 'contextCart:cart-error-insufficient-inventory',
    shouldUpdateInventory: true
  },
  10004: {
    code: '10004',
    messageTranslationTag: 'contextCart:cart-error-exceed-purchase-limit',
    shouldUpdateInventory: true
  },
  '-1': {
    code: '-1',
    messageTranslationTag: 'contextCart:cart-error-unknown'
  }
};

/* ================================================ Local Functions ================================================ */
const validateRequiredItems = async requiredParamsObj => {
  const missingParams = Object.keys(requiredParamsObj).filter(paramKey => {
    const paramValue = requiredParamsObj[paramKey];

    if (!checkHasValue(paramValue, true)) {
      return true;
    }
    return false;
  });

  if (missingParams.length > 0) {
    throw new Error(`Missing ${missingParams.join(', ')} params`);
  }
  return true;
};

const validateQuantityToAdd = async quantity => {
  if (quantity <= 0) {
    throw new Error('Invalid product item quantity');
  }
  return true;
};

const validateFinalQuantity = async (newQuantity, inventory, purchaseLimit) => {
  if (newQuantity > inventory) {
    throw new Error('Insufficient Inventory/Out of stock');
  } else if (checkHasValue(purchaseLimit) && newQuantity > purchaseLimit) {
    throw new Error('Exceed purchase limit');
  }
};

const generateUniqueProductItems = productItems => {
  const uniqueProductItems = [];
  const addedProductItemIds = [];

  for (let i = 0; i < productItems.length; i++) {
    const currentProductItem = productItems[i];
    const existingProductItemIndex = addedProductItemIds.findIndex(productItemId => productItemId !== currentProductItem.id);
    if (existingProductItemIndex > 0) {
      const existingProductItem = uniqueProductItems[existingProductItemIndex];
      addedProductItemIds[existingProductItemIndex] = {
        ...existingProductItem,
        quantity: existingProductItem.quantity + currentProductItem.quantity
      };
    } else {
      uniqueProductItems.push(currentProductItem);
      addedProductItemIds.push(currentProductItem.id);
    }
  }

  return uniqueProductItems;
};

const generateNewProductItemsFromAdd = async (productItems, productItemsToAdd) => {
  const newProductItems = cloneNewObject(productItems);

  for (let i = 0; i < productItemsToAdd.length; i++) {
    const productItem = productItemsToAdd[i];
    const {
      productId,
      varianceId,
      label,
      quantity,
      priceAmount,
      oriPrice,
      inventory,
      purchaseLimit,
      coverPhotoUrl,
      coverPhoto,
      description
    } = productItem;

    await validateRequiredItems({ productId, quantity, priceAmount });
    await validateQuantityToAdd(quantity);

    const id = varianceId ? `${productId}_${varianceId}` : productId;

    const existingProductItemIndex = newProductItems.findIndex(productItem => {
      return productItem.id === id;
    });
    const existingProductItem = existingProductItemIndex > -1 ? newProductItems[existingProductItemIndex] : null;
    const newQuantity = existingProductItem ? existingProductItem.quantity + quantity : quantity;
    await validateFinalQuantity(newQuantity, inventory, purchaseLimit);

    if (!existingProductItem) {
      newProductItems.push({
        id,
        productId,
        varianceId,
        label,
        quantity,
        priceAmount,
        oriPrice,
        inventory,
        purchaseLimit,
        coverPhotoUrl,
        coverPhoto,
        description
      });
    } else {
      newProductItems[existingProductItemIndex] = {
        ...existingProductItem,
        priceAmount,
        oriPrice,
        coverPhotoUrl,
        coverPhoto,
        label,
        quantity: existingProductItem.quantity + quantity,
        description
      };
    }
  }

  return newProductItems;
};

const generateProductItemsWithError = (productItems, productItemErrors, t) => {
  return productItems.map(productItem => {
    const foundProductItemError = findProductItemError(productItem, productItemErrors);

    if (foundProductItemError) {
      let productItemErrorObj = PRODUCT_ITEM_ERRORS_MAPPING[foundProductItemError.error.code];
      if (productItemErrorObj) {
        productItemErrorObj.message = t(productItemErrorObj.messageTranslationTag);
      } else {
        productItemErrorObj = {
          code: PRODUCT_ITEM_ERRORS_MAPPING['-1'].code,
          message: t(PRODUCT_ITEM_ERRORS_MAPPING['-1'].messageTranslationTag, { serverErrorMessage: foundProductItemError.error.message })
        };
      }

      const productItemWithError = {
        ...productItem,
        error: { code: productItemErrorObj.code, message: productItemErrorObj.message }
      };

      if (productItemErrorObj.shouldUpdateInventory) {
        productItemWithError.inventory = foundProductItemError.error.inventory;
        productItemWithError.purchaseLimit = foundProductItemError.error.purchaseLimit;
      }

      return productItemWithError;
    } else if (productItem.error) {
      return { ...productItem, error: undefined };
    }

    return productItem;
  });
};

const resetProductItemsError = productItems => {
  return productItems.map(productItem => {
    return { ...productItem, error: undefined };
  });
};

const findProductItemError = (productItem, resDataErrors) => {
  return resDataErrors.find(productItemError => {
    const matchedProductId = productItem.productId === productItemError.productId;
    if (!matchedProductId) {
      return false;
    } else {
      if (!productItem.varianceId) {
        return true;
      } else if (productItem.varianceId === productItemError.varianceId) {
        return true;
      }
    }
    return false;
  });
};

// ================================================ Hooks
// ----------------------------------------- Cart
const useCartProductItemsCache = () => {
  const [cookies, setCookies] = useCookies([COOKIE_KEY_SESSION]);
  const { value, isInit: isLocalStorageInit, updateValue, clearValue } = useLocalStorage(LS_KEY_CART_PRODUCT_ITEMS, { isValueJson: true });
  const [isInit, setIsInit] = useState(false);

  const productItemsCache = useMemo(() => guard(() => value, []), [value]);

  const updateProductItemsCache = useCallback(
    newProductItems => {
      updateValue(newProductItems);
    },
    [updateValue]
  );

  const removeProductItemsCache = useCallback(() => {
    clearValue();
  }, [clearValue]);

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);

      if (!cookies[COOKIE_KEY_SESSION]) {
        clearValue();
      }
      setCookies(COOKIE_KEY_SESSION, true, { expires: addMoment(new Date(), 7, 'days', { returnFormat: DATE_FORMAT_DATE_OBJ }) });
    }
  }, [isInit, cookies, setCookies, clearValue]);

  return { productItemsCache, isInit: isLocalStorageInit && isInit, updateProductItemsCache, removeProductItemsCache };
};

const useCart = t => {
  const [isInit, setIsInit] = useState(false);
  const [productItems, setProductItems] = useState([]);
  const [isCheckingValidity, setIsCheckingValidity] = useState(false);
  const { productItemsCache, isInit: isCacheInit, updateProductItemsCache } = useCartProductItemsCache();

  useEffect(() => {
    if (isCacheInit && !isInit) {
      setProductItems(productItemsCache);
      setIsInit(true);
    }
  }, [isCacheInit, isInit, productItemsCache, productItems]);

  useEffect(() => {
    if (isInit && productItems) {
      updateProductItemsCache(productItems);
    }
  }, [productItems, updateProductItemsCache, isInit]);

  const addProductItems = useCallback(
    async productItemsToAdd => {
      const uniqueProductItemsToAdd = generateUniqueProductItems(productItemsToAdd);
      const newProductItems = await generateNewProductItemsFromAdd(productItems, uniqueProductItemsToAdd);

      setProductItems(newProductItems);
    },
    [productItems]
  );

  const removeProductItem = useCallback(async indexToRemove => {
    setProductItems(currentProductItems => {
      const newProductItems = currentProductItems.filter((productItem, index) => {
        return index !== indexToRemove;
      });
      return newProductItems;
    });
  }, []);

  const updateProductItemQuantity = useCallback(
    async (indexToUpdate, newQuantity) => {
      const existingProductItem = productItems[indexToUpdate];
      await validateFinalQuantity(newQuantity, existingProductItem.inventory, existingProductItem.purchaseLimit);

      productItems[indexToUpdate] = {
        ...existingProductItem,
        quantity: newQuantity,
        error:
          existingProductItem.errorCode === PRODUCT_ITEM_ERRORS_MAPPING[10001].errorCode ||
          existingProductItem.errorCode === PRODUCT_ITEM_ERRORS_MAPPING[10002].errorCode
            ? undefined
            : existingProductItem.errorCode
      };
      setProductItems([...productItems]);
    },
    [productItems]
  );

  const updateProductItemsValidity = useCallback(async () => {
    setIsCheckingValidity(true);
    return postCartValidate(productItems)
      .then(resData => {
        const newProductItems = resetProductItemsError(productItems);
        setProductItems(newProductItems);

        return resData;
      })
      .catch(errRes => {
        if (errRes.errorCode === PRODUCT_ITEM_ERROR_CODE && errRes.errors && errRes.errors.length > 0) {
          const newProductItems = generateProductItemsWithError(productItems, errRes.errors, t);

          setProductItems(newProductItems);
          throw new Error('One or more cart products are invalid. Please remove them before proceed to checkout');
        } else {
          throw errRes;
        }
      })
      .finally(() => {
        setIsCheckingValidity(false);
      });
  }, [productItems, t]);

  const checkoutCart = useCallback(
    async (customerInfo = {}, orderToCombine) => {
      await validateRequiredItems({
        name: customerInfo.name,
        contact: customerInfo.contact
      });

      return postCheckoutCart(customerInfo, productItems, orderToCombine)
        .then(resData => {
          setProductItems([]);
          return resData;
        })
        .catch(errRes => {
          if (errRes.errorCode === PRODUCT_ITEM_ERROR_CODE && errRes.errors && errRes.errors.length > 0) {
            const newProductItems = generateProductItemsWithError(productItems, errRes.errors, t);
            setProductItems(newProductItems);

            throw new Error('One or more cart products are invalid. Please remove them before proceed to checkout');
          } else {
            throw errRes;
          }
        });
    },
    [productItems, t]
  );

  return {
    productItems,
    isCheckingValidity,
    addProductItems,
    removeProductItem,
    updateProductItemQuantity,
    updateProductItemsValidity,
    checkoutCart
  };
};

const useCacheCustomer = () => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY_CUSTOMER]);

  const update = useCallback(
    newCustomer => {
      setCookie(
        COOKIE_KEY_CUSTOMER,
        { _id: newCustomer._id, name: newCustomer.name, email: newCustomer.email, contact: newCustomer.contact },
        { expires: addMoment(new Date(), 3, 'days', { returnFormat: DATE_FORMAT_DATE_OBJ }) }
      );
    },
    [setCookie]
  );

  const reset = useCallback(() => {
    removeCookie(COOKIE_KEY_CUSTOMER);
  }, [removeCookie]);

  return { customer: cookies[COOKIE_KEY_CUSTOMER], update, reset };
};

const useExistingOrders = ({ isLoadingRoot }) => {
  const [isInitSourceOrder, setIsInitSourceOrder] = useState(false);

  const query = useLocationQuery();
  const { value: sourceOrder, isInit: isSessionStorageInit, updateValue, clearValue } = useLocalStorage(LS_KEY_CART_SOURCE_ORDER, {
    isValueJson: true,
    isSession: true
  });
  const { customer, update: updateCacheCustomer, reset: resetCacheCustomer } = useCacheCustomer();
  const { data: customerPendingOrders, isLoading: isLoadingCustomerPendingOrders } = useGetCustomerPendingOrders(
    !isLoadingRoot && isInitSourceOrder && customer ? customer._id : undefined
  );

  useEffect(() => {
    if (!isLoadingRoot && !isInitSourceOrder && isSessionStorageInit) {
      setIsInitSourceOrder(true);

      if (query.orderNumber && query.accessCode) {
        postGetPendingOrder(query.orderNumber, query.accessCode)
          .then(order => {
            updateValue({ orderNumber: query.orderNumber, accessCode: query.accessCode });
            updateCacheCustomer(order.customer);
          })
          .catch(ex => {
            clearValue();
            resetCacheCustomer();
            logError('Invalid order to combine', ex);
          });
      }
    }
  }, [isLoadingRoot, isInitSourceOrder, isSessionStorageInit, query, updateValue, clearValue, updateCacheCustomer, resetCacheCustomer]);

  return { isLoading: !isInitSourceOrder && isLoadingCustomerPendingOrders, sourceOrder, customer, updateCacheCustomer, customerPendingOrders };
};

/* ================================================ Main Component ================================================ */
const ContextCartProviderPure = ({ propsContextRoot, children }) => {
  const { isLoading: isLoadingRoot, isCompactLayoutView } = propsContextRoot;
  const { t } = useTranslation(['contextCart']);
  const {
    productItems,
    isCheckingValidity,
    addProductItems,
    removeProductItem,
    updateProductItemQuantity,
    updateProductItemsValidity,
    checkoutCart
  } = useCart(t);
  const { isLoading: isLoadingExistingOrders, sourceOrder, customer, updateCacheCustomer, customerPendingOrders } = useExistingOrders({
    isLoadingRoot
  });
  const { isLoading } = useIsLoadings([isLoadingExistingOrders]);

  const propsContextCart = useMemo(
    () => ({
      isLoading,

      productItems,
      isCheckingValidity,
      isCompactLayoutView,
      addProductItems,
      removeProductItem,
      updateProductItemQuantity,
      updateProductItemsValidity,
      checkoutCart,

      sourceOrder,
      customer,
      updateCacheCustomer,
      customerPendingOrders
    }),
    [
      isLoading,

      productItems,
      isCheckingValidity,
      isCompactLayoutView,
      addProductItems,
      removeProductItem,
      updateProductItemQuantity,
      updateProductItemsValidity,
      checkoutCart,

      sourceOrder,
      customer,
      updateCacheCustomer,
      customerPendingOrders
    ]
  );

  return <ContextCart.Provider value={{ propsContextCart }}>{children}</ContextCart.Provider>;
};

export const ContextCartProvider = withContextRoot(ContextCartProviderPure);

export const withContextCart = Component => {
  const ContextCartComponent = props => (
    <ContextCart.Consumer>{({ propsContextCart }) => <Component propsContextCart={propsContextCart} {...props} />}</ContextCart.Consumer>
  );
  return ContextCartComponent;
};
