import { Badge, Button, Col, Layout, Row, Skeleton, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { withContextCart } from 'contexts/ContextCart/ContextCart';
import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import ProfileAvatar from 'components/_local/ProfileAvatar/ProfileAvatar';

import { useNav } from 'utils/routes';
import { constructCheckoutURL, getCartRoute, getHomeRoute, getRefundPolicyRoute } from 'utils/routes';

import {
  CartIcon,
  StyledLayoutContainer,
  StyledContent,
  StyledContentBanner,
  StyledHeader,
  StyledFooter,
  StyledFooterLink
} from './LayoutMain.styles';
import Logo from 'images/logo.png';

const homeRoute = getHomeRoute();
const cartRoute = getCartRoute();
const refundPolicyRoute = getRefundPolicyRoute();

const LayoutMain = ({ children, isOnLive = true, nextLive, propsContextRoot, propsContextCart }) => {
  const { isLoading: isLoadingContextRoot, storeInfo } = propsContextRoot;
  const { productItems, customerPendingOrders } = propsContextCart;

  const { t } = useTranslation(['layoutMain']);
  const nav = useNav();

  const totalCartProductItems = productItems && productItems.length;
  const hasPendingOrdersBanner = !!customerPendingOrders && customerPendingOrders.length > 0;

  return (
    <Layout style={{ backgroundColor: '#FAFAFA' }}>
      <StyledLayoutContainer>
        <StyledHeader>
          <Row justify="space-between" align="middle">
            <Col>
              {!isLoadingContextRoot ? (
                <ProfileAvatar src={storeInfo.customDomainLogoUrl || Logo} size={48} isSquare isHideShadow onClick={() => nav(homeRoute.path)} />
              ) : null}
            </Col>
            <Col>
              <Space>
                <LanguageSwitcher />
                <Badge count={totalCartProductItems} overflowCount={10} offset={[totalCartProductItems < 10 ? -4 : 4, 8]}>
                  <Button type="text" icon={<CartIcon />} onClick={() => nav(cartRoute.path)} />
                </Badge>
              </Space>
            </Col>
          </Row>
        </StyledHeader>
        {isLoadingContextRoot ? (
          <Skeleton active />
        ) : (
          <>
            {hasPendingOrdersBanner &&
              customerPendingOrders.map(pendingOrder => {
                return (
                  <StyledContentBanner
                    message={
                      <span>
                        <Tag color="#ffba00">#{pendingOrder.orderNumber}</Tag>
                        {t('layoutMain:notification-banner-pending-order-msg')}
                      </span>
                    }
                    banner
                    closable
                    showIcon={false}
                    action={
                      <Button
                        size="small"
                        type="text"
                        onClick={() => window.open(constructCheckoutURL(pendingOrder.orderNumber, pendingOrder.accessCode))}
                      >
                        {t('layoutMain:notification-banner-pending-order-action-text')}
                      </Button>
                    }
                  />
                );
              })}
            <StyledContent>{children}</StyledContent>
          </>
        )}
        <StyledFooter>
          <span>Powered by Ulive Tech Sdn Bhd @ 2024</span>
          {storeInfo.refundPolicy ? <StyledFooterLink to={refundPolicyRoute.path}>{t('layoutMain:footer-refund-policy')}</StyledFooterLink> : null}
        </StyledFooter>
      </StyledLayoutContainer>
    </Layout>
  );
};

export default withContextRoot(withContextCart(LayoutMain));
