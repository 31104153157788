import styled from '@emotion/styled';
import { Button, Col, Row } from 'antd';

import ImagesPreview from 'components/_local/ImagesPreview/ImagesPreview';
import PriceDisplay from 'components/_local/PriceDisplay/PriceDisplay';

export const ContentContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 0 16px;

  @media (min-width: 768px) {
    margin: 0;
    padding: 16px;
  }

  @media (min-width: 1200px) {
    padding: 16px 32px;
  }
`;

export const ContentSummaryInfoContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  /* padding: 0px 24px; */
`;

export const ContentBasicInfoContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 0 16px;

  @media (min-width: 768px) {
    margin: 0;
    padding: 24px;
  }

  @media (min-width: 1200px) {
    padding: 24px 32px;
  }
`;

export const ProductInfoDesc = styled.p`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const ProductInfoDescMobile = styled.p`
  font-size: 12px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const ProductInfoDescShowButton = styled(Button)`
  color: #8c8c8c;
  padding: 0;
  font-size: 12px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CartProductItemRow = styled(Row)`
  @media (min-width: 768px) {
    margin-top: 24px;
  }
`;

export const OriPricingInfoLabel = styled(PriceDisplay)`
  font-size: 12px;
  color: grey;
  text-decoration: line-through;
  margin-right: 4px;
`;

export const PricingInfoLabel = styled(PriceDisplay)`
  color: ${props => (props.hasOriPrice ? props.theme.color.secondary : '#000000D9')};
  font-size: 1.5em;
  margin-bottom: 8px;
  font-weight: bold;

  @media (min-width: 768px) {
    margin-bottom: 24px;
    font-weight: bold;
  }
`;

export const PricingActionsContainer = styled(Row)`
  width: 100% !important;
  justify-content: left;

  @media (min-width: 768px) {
    justify-content: right;
  }
`;

export const PhotoCol = styled(Col)`
  align-items: center;
  background-color: #dfdfdf;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0 !important;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StyledImagesPreview = styled(ImagesPreview)`
  max-height: 160px;

  @media (min-width: 768px) {
    max-height: 264px;
  }

  @media (min-width: 1200px) {
    max-height: 320px;
  }
`;
