import styled from '@emotion/styled';
import { Alert, Layout } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Content, Header, Footer } = Layout;

const mobileContentPadding = 32; // if change, please change for homepage too.
const tabletContentPadding = 60; // if change, please change for homepage too.

export const StyledLayoutContainer = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
`;

export const StyledHeader = styled(Header)`
  background-color: ${props => props.theme.color.white};
  padding: 0 ${mobileContentPadding}px;
  height: 60px;
  line-height: 60px;

  @media (min-width: 768px) {
    padding: 0 ${tabletContentPadding}px;
  }
`;

export const StyledContent = styled(Content)`
  background-color: ${props => props.theme.color.white};
  min-height: 80vh;
  padding: 0 ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 0 ${tabletContentPadding}px;
  }
`;

export const StyledContentBanner = styled(Alert)`
  padding: 8px ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 8px ${tabletContentPadding}px;
  }
`;

export const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  background-color: #eff2f5;
  padding: 24px ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 24px ${tabletContentPadding}px;
  }
`;

export const StyledFooterLink = styled(Link)`
  width: fit-content;
  padding: 4px 4px 4px 0;
`;

export const CartIcon = styled(ShoppingCartOutlined)`
  color: #4f4f4f;
  font-size: 20px;
`;
