import { useCallback, useEffect, useRef } from 'react';
import { useLocation, matchRoutes, useNavigate } from 'react-router-dom';

import { REACT_APP_LA_DOMAIN } from 'config/env';

const constructRoute = path => ({ path });

export const getRootRoute = () => constructRoute('/');

/** =========================================== Error Routes =========================================== */
export const get404Route = () => constructRoute('/404');

// =========================================== Home
export const getHomeRoute = categoryId => {
  if (categoryId) {
    return constructRoute(`/home?category=${categoryId}`);
  }
  return constructRoute('/home');
};

export const getRefundPolicyRoute = () => constructRoute('/refund-policy');

// =========================================== Product
export const getProductDetailsRoute = (productId = ':productId', postId) => {
  if (postId) {
    return constructRoute(`/products/${productId}?post=${postId}`);
  }
  return constructRoute(`/products/${productId}`);
};

// =========================================== Cart
export const getCartRoute = () => constructRoute('/cart');

/** =========================================== External Routes =========================================== */
// =========================================== LA Checkout
export const constructCheckoutURL = (orderNumber, accessCode) => {
  return `${REACT_APP_LA_DOMAIN}/checkout/${orderNumber}?accessCode=${accessCode}`;
};

// =========================================== React Router Wrapper ===========================================
// This function is needed to make sure that the navigate function
// stay the same to prevent rerender issue. See https://github.com/remix-run/react-router/issues/7634
export const useNav = () => {
  const navigate = useNavigate();
  const navigateRef = useRef({ navigate });

  useEffect(() => {
    navigateRef.current.navigate = navigate;
  }, [navigate]);

  return useCallback((to, options) => {
    navigateRef.current.navigate(to, options);
  }, []);
};

const routes = [getRootRoute(), get404Route(), getHomeRoute(), getProductDetailsRoute(), getCartRoute()];
export const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);

  return route.path;
};
