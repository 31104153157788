import styled from '@emotion/styled';
import { Button, Col, Modal } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';

import ImagesPreview from 'components/_local/ImagesPreview/ImagesPreview';
import PriceDisplay from 'components/_local/PriceDisplay/PriceDisplay';

export const ContainerModal = styled(Modal)`
  width: 100% !important;
  top: 8vh;

  @media (min-width: 768px) {
    width: 80% !important;
  }

  @media (min-width: 1024px) {
    width: 60% !important;
  }

  @media (min-width: 1400px) {
    width: 50% !important;
  }

  @media (min-width: 1600px) {
    width: 40% !important;
  }

  @media (min-width: 2560px) {
    width: 30% !important;
  }
`;

export const PhotoCol = styled(Col)`
  padding: 0 !important;
  align-items: center;
  background-color: #dfdfdf;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StyledImagesPreview = styled(ImagesPreview)`
  max-height: 100%;

  @media (min-width: 768px) {
    max-height: 264px;
  }

  @media (min-width: 1200px) {
    max-height: 320px;
  }
`;

export const ProductName = styled.h2``;

export const ProductKeyword = styled.h2`
  color: grey;
`;

export const ProductDesc = styled.p`
  white-space: pre-wrap;
  color: ${props => props.theme.color.grey};
`;

export const ProductNameWithVariances = styled.h4`
  color: #000000d9;
  margin-bottom: 0 !important;
`;

export const ProductKeywordWithVariances = styled.h4`
  color: grey;
`;

export const QtyButtonGroupContainer = styled.div`
  display: flex;
`;

export const QtyButton = styled(Button)`
  width: 60px;
`;

export const CartIcon = styled(ShoppingCartOutlined)`
  color: #4f4f4f;
  font-size: 20px;
`;

export const OriPricingInfoLabel = styled(PriceDisplay)`
  font-size: 12px;
  color: grey;
  text-decoration: line-through;
  margin-right: 4px;
`;

export const PricingInfoLabel = styled(PriceDisplay)`
  color: ${props => (props.hasOriPrice ? props.theme.color.secondary : '#000000D9')};
  font-size: 1.5em;
  font-weight: bold;
`;
