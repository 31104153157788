import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { QueryClientProvider } from './reactQuery/queryClient';

import { ContextRootProvider } from 'contexts/ContextRoot/ContextRoot';
import { ContextCartProvider } from 'contexts/ContextCart/ContextCart';
import LayoutMain from 'layouts/LayoutMain/LayoutMain';
import Cart from 'pages/Cart/Cart';
import RefundPolicy from 'pages/RefundPolicy/RefundPolicy';
import Home from 'pages/Home/Home';

import NotFound404 from 'pages/404/NotFound404';

import { getHomeRoute, getRefundPolicyRoute, getProductDetailsRoute, getCartRoute, get404Route } from 'utils/routes';

import reportWebVitals from './reportWebVitals';
import { useLoadAllTranslationFiles } from './i18n';
import './index.css';

const homeRoute = getHomeRoute();
const refundPolicyRoute = getRefundPolicyRoute();
const productRoute = getProductDetailsRoute();
const cartRoute = getCartRoute();
const notFoundRoute = get404Route();

const Main = () => {
  useLoadAllTranslationFiles();

  return (
    <Router>
      <ContextRootProvider>
        <ContextCartProvider>
          <LayoutMain>
            <Routes>
              <Route path={homeRoute.path} element={<Home />} />
              <Route path={productRoute.path} element={<Home />} />
              <Route path={cartRoute.path} element={<Cart />} />
              <Route path={refundPolicyRoute.path} element={<RefundPolicy />} />
              <Route path={notFoundRoute.path} element={<NotFound404 />} />
              <Route path="*" element={<Navigate to={homeRoute.path} />} />
            </Routes>
          </LayoutMain>
        </ContextCartProvider>
      </ContextRootProvider>
    </Router>
  );
};

ReactDOM.render(
  <StrictMode>
    <Suspense fallback="loading">
      <HelmetProvider>
        <QueryClientProvider>
          <Helmet>
            <title>Shop with us</title>
          </Helmet>
          <Main />
        </QueryClientProvider>
      </HelmetProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
